<template>

  <PDialog 
    title="Import" 
    classes="left-0 md:left-auto md:w-4/5" 
    @close="$emit('close')"
  >

    <PDialogBody>

      <div class="p-4 border-b border-gray-300 flex-0 bg-gradient-to-b from-gray-100 to-gray-200">

        <form @submit.prevent="onSubmitFile">
          
          <div class="flex flex-col items-center justify-between md:flex-row">
            
            <div class="flex flex-col items-center my-4 md:flex-row md:my-4">
              
              <PFile
                :disabled="isBusy"
                accept=".xls,.xlsx"
                @change="onFileChange"
              />

              <a 
                class="inline-flex items-center ml-4 text-sm hover:underline" 
                :href="templateUrl" 
                download
              >
                <FontAwesomeIcon :icon="['far', 'file-excel']" />
                <div class="ml-2" v-html="$tk('ImportDialog.DownloadExcelTemplate')"></div>
              </a>

            </div>
        
            <div class="flex flex-col items-center md:flex-row space-y-2 md:space-y-0">
            
              <PSelect 
                class="ml-4"
                :disabled="isBusy"
                :items="workSheets"
                v-model="selectedWorkSheet" 
              />

              <PSelect 
                class="ml-4"
                :disabled="isBusy"
                :items="sheetRows" 
                v-model="selectedSheetRow" 
              />

              <PSelect
                class="ml-4"
                :disabled="isBusy"
                :items="formattedValues"
                v-model="selectedFormattedValues"
              />

              <PButton 
                class="ml-4"
                type="submit"    
                :loading="isSubmitting"            
                :disabled="!file || isBusy"                
              >
                <span v-html="$tk('ImportDialog.UploadExcelFile')"></span>
              </PButton>

            </div>

          </div>

        </form>
      </div>
      <div class="flex-1 overflow-y-auto">
        
        <PLoader v-if="isLoading" />
        
        <template v-else>
          <table class="data-table" v-if="transactions.length > 0">
            <thead>
              <tr>
                <th class="text-left" v-html="$tk('Common.Order.RTI')"></th>
                <th class="text-left" v-html="$tk('Common.Order.Quality')" v-if="!isExternal"></th>
                <th class="text-left" v-html="$tk('Common.Order.TransactionType')" v-if="!isExternal"></th>
                <th v-html="$tk('Common.Order.SentDate')"></th>
                <th v-html="$tk('Common.Order.Quantity')" class="text-right"></th>
                <th v-html="$tk('Common.Order.From')" class="text-left"></th>
                <th v-html="$tk('Common.Order.To')" class="text-left"></th>
                <th v-html="$tk('Common.Order.SendersRef')" class="text-left"></th>
                <th v-html="$tk('Common.Order.ReceiversRef')" class="text-left"></th>
                <th v-html="$tk('Common.Order.Message')" class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr 
                v-for="(t, index) in transactions" 
                :key="index"
                :class="{ 
                  'has-errors': t.errorText,
                  'update-ok': t.updateOk

                }"
              >
                <td>{{ t.productId }} {{ t.productName }}</td>
                <td v-if="!isExternal">{{ t.qualityName }}</td>
                <td v-if="!isExternal">{{ t.transactionTypeId }}</td>
                <td class="text-center">{{ t.sendDate | date }}</td>
                <td class="text-right">{{ t.quantity }}</td>
                <td>({{ t.locationIdFrom }}) {{ t.locationNameFrom }}</td>
                <td>({{ t.locationIdTo }}) {{ t.locationNameTo }}</td>
                <td>{{ t.sendersRef }}</td>
                <td>{{ t.receiversRef }}</td>
                <td>
                  
                  <ul class="ml-4 list-disc" v-if="t.errorText">
                    <li v-for="(error, eindex) in t.errorText.split('\n')" :key="eindex" v-show="error">
                      {{ error }}
                    </li>
                  </ul>
                  
                  <span 
                    v-if="t.updateOk" 
                    v-html="$tk('ImportDialog.ImportOk')"
                  ></span>
                  
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </div>

    </PDialogBody>
    <PDialogActions>
      <div class="flex items-center justify-between w-full">
        <div>
          
          <PButton 
            color="danger" 
            icon="trash-alt"
            @click="onReject"
            :loading="isRejecting"
            :disabled="isBusy"
            v-if="transactions.length > 0"            
          >
            <span v-html="$tk('Common.Actions.Empty')"></span>
          </PButton>

        </div>
        
        <div>

          <PButton 
            color="secondary" 
            @click="onCancel"
            :disabled="isBusy"            
          >
            <span v-html="$tk('Common.Actions.Cancel')"></span>
          </PButton>

          <PButton 
            class="ml-2" 
            color="primary" 
            @click="onAccept"
            :loading="isAccepting"
            :disabled="!canAccept || isBusy"            
          >
            <span v-html="$tk('Common.Actions.Approve')"></span>
          </PButton>

        </div>
      </div>
    </PDialogActions>
  </PDialog>
</template>

<script>
import http from "@/http"
import { mapGetters } from "vuex"
import { every } from "lodash"
export default {

  props: {
    importType: {
      type: String,
      default: ""
    }
  },

  data () {
    return {
      file: "",
      transactions: [],
      workSheets: [],
      selectedWorkSheet: 0,
      sheetRows: [],
      selectedSheetRow: 1,      
      formattedValues: [
        { value: false, text: this.$tk("ImportDialog.OriginalValues") },
        { value: true, text: this.$tk("ImportDialog.OriginalValues") }
      ],
      selectedFormattedValues: false,
      isLoading: false,
      isSubmitting: false,
      isRejecting: false,
      isAccepting: false,
    }
  },

  computed: {

    ...mapGetters([
      "user",
      "location"
    ]),  

    assetsBaseUrl () {
      return this.$appConfig.assetsBaseUrl
    },

    canAccept () {      

      const statusesOk = every(this.transactions, t => t.status === "")
      const allUpdated = every(this.transactions, t => t.updateOk)

      return this.transactions.length &&                          
             statusesOk &&
             allUpdated === false &&
             this.isBusy === false
    },

    isBusy () {
      return this.isSubmitting || 
             this.isAccepting ||
             this.isRejecting
    },

    isExternal () {
      return this.importType === "External" 
    },

    templateUrl () {
      return this.isExternal 
      ? `${this.assetsBaseUrl}/Content/Files/ExternalImportTemplate.xlsx`
      : `${this.assetsBaseUrl}/Content/Files/ImportTemplate.xlsx`
    }
  },

  methods: {

    onFileChange (files) {
      this.file = files[0]
    },

    async onSubmitFile () {
      
      let formData = new FormData()
      
      formData.append("userId", this.user.id)
      formData.append("locationIdUser", this.location.id)
      formData.append("workSheet", this.selectedWorkSheet)
      formData.append("sheetRow", this.selectedSheetRow)
      formData.append("formattedValues", this.selectedFormattedValues)

      formData.append("file", this.file)

      this.isSubmitting = true 

      try {

        await http.post(this.isExternal ? "ExternalExcelImport" : "ExcelImport",
          formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
          }
        )        

        await this.init()

      } catch (error) {

        this.$store.dispatch("notify", { 
          type: "negative", 
          text: error.reason 
        })
        
      }

      this.isSubmitting = false 
    },

    async onReject () {  

      this.isRejecting = true

      try {

        await http.post(this.isExternal ? "ExternalExcelReject" : "ExcelReject", {
          userId: this.user.id,
          locationIdUser: this.location.id
        })

        this.$store.dispatch("notify", { 
          type: "positive", 
          text: this.$tk("ImportDialog.ImportedRowsCleared", true) 
        })

      } catch (error) {

        this.$store.dispatch("notify", { 
          type: "negative", 
          text: error.reason 
        })

      }

      this.isRejecting = false

      this.init()
    },

    async onAccept () {

      this.isAccepting = true

      try {

        await http.post(this.isExternal ? "ExternalExcelAccept" : "ExcelAccept", {
          userId: this.user.id,
          locationIdUser: this.location.id
        })

        this.$store.dispatch("notify", { 
          type: "positive", 
          text: this.$tk("ImportDialog.ImportedRowsApproved", true)
        })

        this.$emit("close")

      } catch (error) {
        
        this.$store.dispatch("notify", { 
          type: "negative", 
          text: error.reason 
        })


      }            

      this.isAccepting = false
    },

    async init () {

      this.file = ""

      this.workSheets = []
      
      for (let i = 0; i < 10; i++) {
        this.workSheets.push({ value: i, text: `${this.$tk("ImportDialog.ReadSheet")} ${i + 1}` })
      }

      this.sheetRows = []

      this.sheetRows.push({ value: 0, text: `${this.$tk("ImportDialog.FromRow")} 1 (${this.$tk("ImportDialog.FileWithoutHeader")})` })
      this.sheetRows.push({ value: 1, text: `${this.$tk("ImportDialog.FromRow")} 2 (${this.$tk("ImportDialog.FileWithHeader")})` })

      for (let i = 3; i <= 4; i++) {
        this.sheetRows.push({ value: i, text: `${this.$tk("ImportDialog.FromRow")} ${i} (${this.$tk("ImportDialog.FileWith")} ${i - 1} ${this.$tk("ImportDialog.HeaderLines")})` })
      }

      this.isLoading = true
      
      try {
        
        this.transactions = await http.get(this.isExternal ? "ExternalExcel" : "Excel")

      } catch (error) {

        this.$store.dispatch("notify", {
          type: "negative",
          text: error.reason
        })

      }

      this.isLoading = false

    },

    onCancel () {
      this.$emit("close")
    }

  },

  async created () {
    await this.init ()    
  }

}

</script>

<style scoped>

th {
  @apply p-4 leading-4 bg-gradient-to-b from-gray-500 to-gray-600;
}

tr {
  background-color: theme('colors.gray.50');
}

tr:nth-child(odd) {
  background-color: theme('colors.gray.100')
}

tr:hover {
  background-color: theme('colors.orange.200');
}

td {
  @apply px-4 py-2;
  border-bottom: 1px solid theme('colors.gray.300');
  border-right: 1px solid theme('colors.gray.300');
}

tr.has-errors {
  background-color: theme('colors.red.200');
  color: theme('colors.red.700');
}

tr.has-errors td {  
  border-bottom: 1px solid theme('colors.red.300');
  border-right: 1px solid theme('colors.red.300');
}

tr.update-ok {
  background-color: theme('colors.green.200');
  color: theme('colors.green.700');
}

tr.update-ok td {  
  border-bottom: 1px solid theme('colors.green.300');
  border-right: 1px solid theme('colors.green.300');
}



</style>